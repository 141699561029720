import * as React from "react";
import * as Rebass from 'rebass';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button'
import * as Color from 'color'
import Link from '@material-ui/core/Link';
import { Link as RouterLink, GatsbyLinkProps, navigate, useStaticQuery, graphql } from 'gatsby'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { Wrapper } from '../../components/wrapper';
import { HR } from "../../components/hr";
import { Grid } from "@material-ui/core";

// const PageTitle = styled(Rebass.Text).attrs({
//   fontFamily: 'Avenir',
//   fontSize: [5]
// })``

// const PageHeader = styled(Rebass.Flex).attrs({
//   flexDirection: 'column',
//   py: 4,
//   justifyContent: 'flex-start'
// })`
//   width: 100%;
//   max-width: 1000px;
// `

// const ResourceCard = styled(Rebass.Card).attrs({
//   fontSize: 5,
//   fontWeight: 'bold',
//   width: [ 1, 1, 1/2 ],
//   p: 5,
//   my: 3,
//   // bg: '#f6f6ff',
//   borderRadius: 5,
//   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)'
// })``

// const CardContainer = styled(Rebass.Flex).attrs({
//   flexDirection: 'row',
//   justifyContent: 'space-between'
// })``

// const SubHeader = styled(Rebass.Text).attrs({
//   fontFamily: 'Avenir',
//   fontWeight: 300,
//   color: '#071D3B'
// })``

const BACKGROUND_COLOR = '#F5F7FA';

const Wrapper = styled(Box)({
  backgroundColor: BACKGROUND_COLOR
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #efefef',
      zIndex: 999
    },
    icon: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0
      }
    },
    container: {
      paddingLeft: 0,
      paddingRight: 0
    },
    formbuttonRoot: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1.2),
      paddingBottom: theme.spacing(1.2),
      boxShadow: '0px 3px 20px rgba(60, 137, 239, 0.5)',
      marginTop: '32px',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    formbuttonLabel: {
      whiteSpace: 'pre',
      color: "#3c8aef",
    },
    consentbuttonRoot: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1.2),
      paddingBottom: theme.spacing(1.2),
      boxShadow: '0px 3px 20px rgba(17, 203, 95, 0.5)',
      marginTop: '32px',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    consentbuttonLabel: {
      whiteSpace: 'pre',
      color: "#11cb5f",
    },
    instructionbuttonRoot: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1.2),
      paddingBottom: theme.spacing(1.2),
      boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.23)',
      marginTop: '32px',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    instructionbuttonLabel: {
      whiteSpace: 'pre',
      color: "#000",
    },
  })

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

const LinkComponent = React.forwardRef<HTMLAnchorElement, Omit<GatsbyLinkProps<{}>, 'ref'>>((props, ref) => (
  // @ts-ignore
  <RouterLink innerRef={ref} {...props} />
))

const PrimaryText = styled(Rebass.Text)`
  font-family: Avenir;
  font-weight: 300;
  color: #071D3B;
  letter-spacing: 0;
`

PrimaryText.defaultProps = {
  fontSize: 5
}

const SecondaryText = styled(Rebass.Text)`
  opacity: 0.5;
  font-family: 'Open Sans';
  font-weight: 300;
  /* font-size: 16px; */
  color: #071D3B;
  letter-spacing: 0;
  line-height: 1.5;
  /* line-height: 27px; */
`

SecondaryText.defaultProps = {
  fontSize: [3],
  mt: 2
}

const IconWrapper = styled(Rebass.Box)`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 4px 15px 0 rgba(7,29,59,0.06);
`

IconWrapper.defaultProps = {
  mr: [4],
  mb: [4, 0]
}

const LeftWrapper = styled(Rebass.Flex)``

LeftWrapper.defaultProps = {
  flexDirection: 'row',
  width: [1, 3 / 4],
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: ['center', 'flex-start']
}

const NoneLink = withStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      textDecoration: 'none'
    },
  }
}))(Link);

interface Ipad {
  ipadDetect: boolean;
}

export default withStyles(styles)(({ classes }: WithStyles<typeof styles>) => {
  const data = useStaticQuery(graphql`
    query {
      downloadFormsIcon: file(
        relativePath: {
          eq: "icons/download-forms.svg"
        },
        sourceInstanceName: {
          eq: "assets"
        }
      ) {
        publicURL
      }
    }
  `);

  const initialIpad: Ipad = {
    ipadDetect: false
  };

  const [ipad, setIpad] = React.useState<Ipad>(initialIpad);

  function isDeviceiPad() {
    const ipadDetectStatus = navigator.platform.match(/ipad/i);
    if (ipadDetectStatus === null) {
      return false;
    } else {
      return true;
    }
  }

  React.useEffect(() => {
    setIpad({
      ipadDetect: isDeviceiPad()
    })
  }, [ipad.ipadDetect]);

  return (
    <Layout>
      <Container maxWidth="md">

        <SEO title="Resource Center" />

        <Box my={3}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={LinkComponent} color="inherit" to="/en">
              Home
            </Link>
            <Typography color="textPrimary">Resources</Typography>
          </Breadcrumbs>

        </Box>

        <HR />

        <Box mt={3}>

          <Typography variant='h4' component='h1'>
            Resource Center
          </Typography>
          <Box my={4}>
            <Typography>
              Access everything from Forms to Instructions and Consents. You can download any file, fill it and bring it to our office.
            </Typography>
          </Box>

        </Box>
      </Container>

      <Wrapper py={3}>
        <Container maxWidth="md">
          <Box>
            <LeftWrapper>
              <IconWrapper>
                <Rebass.Image src={data.downloadFormsIcon.publicURL} />
              </IconWrapper>
              <Rebass.Box my={[3, 'auto']} mx={[3, 0]} width={[1, 'auto']}>
                <PrimaryText>Download Forms</PrimaryText>
                <SecondaryText>Looking to save time? Fill out our forms before your visit.</SecondaryText>
              </Rebass.Box>
            </LeftWrapper>
          </Box>
          <Grid container>

            <Grid item xs={12}
              md={4}
            >
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/forms/New+Patient+Registration.pdf'
                target='_blank'
              >
                <Button
                  variant="outlined"
                  color="primary"
                  classes={{
                    root: classes.formbuttonRoot,
                    label: classes.formbuttonLabel
                  }}
                >
                  New Patient Registration
                </Button>
              </NoneLink>
            </Grid>

            <Grid item xs={12}
              md={4}
            >

              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/forms/REQUEST+OF+DENTAL+RECORDS-TO+ARI+DENTAL.pdf' target='_blank'>
                <Button
                  variant="outlined"
                  color="primary"
                  classes={{
                    root: classes.formbuttonRoot,
                    label: classes.formbuttonLabel
                  }}
                >
                  Request Dental Records
                </Button>
              </NoneLink>
            </Grid>

            <Grid item xs={12}
              md={4}
            >
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/forms/RELEASE+OF+DENTAL+RECORDS-FROM+ARI+DENTAL.pdf' target='_blank'>
                <Button
                  variant="outlined"
                  color="primary"
                  classes={{
                    root: classes.formbuttonRoot,
                    label: classes.formbuttonLabel
                  }}
                >
                  Release Dental Records
                </Button>
              </NoneLink>
            </Grid>

          </Grid>
        </Container>
      </Wrapper>

      <Wrapper py={3} mt={3}>
        <Container maxWidth="md">
          <Box>
            <LeftWrapper>
              <IconWrapper>
                <Rebass.Image src={data.downloadFormsIcon.publicURL} />
              </IconWrapper>
              <Rebass.Box my={[3, 'auto']} mx={[3, 0]} width={[1, 'auto']}>
                <PrimaryText>Download Consents</PrimaryText>
              </Rebass.Box>
            </LeftWrapper>
          </Box>
          <Grid container>

            <Grid item xs={12} md={3}>
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/consents/CONSENT+FOR+MEDICAL+INFORATION-ARI+DENTAL.pdf'
                target='_blank'
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  classes={{
                    root: classes.consentbuttonRoot,
                    label: classes.consentbuttonLabel
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Medical Information&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Button>
              </NoneLink>
            </Grid>

            <Grid item xs={12} md={3}>
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/consents/CONSENT+FOR+GRAFT-PERIODONTAL+SURGERY-ARI+DENTAL.pdf'
                target='_blank'
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  classes={{
                    root: classes.consentbuttonRoot,
                    label: classes.consentbuttonLabel
                  }}
                >
                  Graft-Periodontal Surgery
                </Button>
              </NoneLink>
            </Grid>

            <Grid item xs={12} md={3}>
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/consents/CONSENT+FOR+ORTHO+AND+INVISALIGN-ARI+DENTAL.pdf'
                target='_blank'
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  classes={{
                    root: classes.consentbuttonRoot,
                    label: classes.consentbuttonLabel
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Ortho and Invisalign&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Button>
              </NoneLink>
            </Grid>

            <Grid item xs={12} md={3}>
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/consents/CONSENT+FOR+IN+OFFICE+TOOTH+WHITENING.pdf'
                target='_blank'
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  classes={{
                    root: classes.consentbuttonRoot,
                    label: classes.consentbuttonLabel
                  }}
                >
                  In Office Tooth Whitening
                </Button>
              </NoneLink>
            </Grid>

            <Grid item xs={12} md={3}>
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/consents/CONSENT+FOR+ENDODONTIC-ARIDENTAL.pdf'
                target='_blank'
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  classes={{
                    root: classes.consentbuttonRoot,
                    label: classes.consentbuttonLabel
                  }}
                >
                  Endodontic
                </Button>
              </NoneLink>
            </Grid>

            <Grid item xs={12} md={3}>
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/consents/CONSENT+FOR+ORAL+SURGERY.pdf'
                target='_blank'
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  classes={{
                    root: classes.consentbuttonRoot,
                    label: classes.consentbuttonLabel
                  }}
                >
                  Oral Surgery
                </Button>
              </NoneLink>
            </Grid>

            <Grid item xs={12} md={3}>
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/consents/CONSENT+FOR+IMPLANT-ARI+DENTAL.pdf'
                target='_blank'
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  classes={{
                    root: classes.consentbuttonRoot,
                    label: classes.consentbuttonLabel
                  }}
                >
                  Implant
                </Button>
              </NoneLink>
            </Grid>

          </Grid>
        </Container>
      </Wrapper>

      <Wrapper py={3} mt={3} mb={5}>
        <Container maxWidth="md">
          <Box>
            <LeftWrapper>
              <IconWrapper>
                <Rebass.Image src={data.downloadFormsIcon.publicURL} />
              </IconWrapper>
              <Rebass.Box my={[3, 'auto']} mx={[3, 0]} width={[1, 'auto']}>
                <PrimaryText>Download Instructions</PrimaryText>
              </Rebass.Box>
            </LeftWrapper>
          </Box>
          <Grid container>

            <Grid item xs={12} md={2}>
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/instructions/Orthodontics+Instructions.pdf'
                target='_blank'
              >
                <Button
                  variant="outlined"
                  classes={{
                    root: classes.instructionbuttonRoot,
                    label: classes.instructionbuttonLabel
                  }}
                >
                  Orthodontics&nbsp;&nbsp;
                </Button>
              </NoneLink>
            </Grid>

            <Grid item xs={12} md={4}>
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/instructions/Post+op+for+In-Office+teeth+whitening.pdf'
                target='_blank'
              >
                <Button
                  variant="outlined"
                  classes={{
                    root: classes.instructionbuttonRoot,
                    label: classes.instructionbuttonLabel
                  }}
                >
                  Post op for In-Offic teeth whitening
                </Button>
              </NoneLink>
            </Grid>

            <Grid item xs={12} md={3}>
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/instructions/Post+Surgical+Instructions-+Short.pdf'
                target='_blank'
              >
                <Button
                  variant="outlined"
                  classes={{
                    root: classes.instructionbuttonRoot,
                    label: classes.instructionbuttonLabel
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Post Surgical Short&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Button>
              </NoneLink>
            </Grid>

            <Grid item xs={12} md={3}>
              <NoneLink
                href='https://s3.amazonaws.com/media.ari.dental/pdf/instructions/Post+Surgical+lnstructions-+General.pdf'
                target='_blank'
              >
                <Button
                  variant="outlined"
                  classes={{
                    root: classes.instructionbuttonRoot,
                    label: classes.instructionbuttonLabel
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Post Surgical General&nbsp;&nbsp;
                </Button>
              </NoneLink>
            </Grid>

          </Grid>
        </Container>
      </Wrapper>
    </Layout>
  );
}
)
