import React from 'react'
import { styled } from '@material-ui/styles'

const BORDER_COLOR = '#E4E7EB'

export const HR = styled('div')({
  height: '1px',
  width: '100%',
  backgroundColor: BORDER_COLOR
})
